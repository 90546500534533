import React from "react";
import Multimedia from "../components/Container/Multimedia";
import { Container } from "reactstrap";
import { Card } from "react-bootstrap";

const Appeals = () => {
  return (
    <div
      style={{
        disptextlay: "flex",
        justifyContent: "justify",
        alignItems: "justify",
        padding: "5em",
        textAlign: "justify",
      }}
      className="row"
    >
      <div className="col-sm-9">
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <h1>Esquema de Atención</h1>
        </div>
        <br />
        <div>
          <Container fluid="md">
            <Card>
              <Card.Img variant="top" src={require("../images/f8.png")} />
            </Card>
          </Container>
        </div>
      </div>
      <div className="col-md-3">
        <Multimedia></Multimedia>
      </div>
    </div>
  );
};
export default Appeals;
