import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import YouTube from "react-youtube";
import Galeria from "./Galeria";

class Multimedia extends React.Component {
  render() {
    const opts = {
      height: "300",
      width: "280",
      playerVars: {
        autoplay: 1,
      },
    };

    return (
      <div>
        <div>
          <h4
            style={{
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            Mire nuestros trabajos
          </h4>
          <div>
            <Galeria></Galeria>
          </div>
        </div>
        <hr />
        <div>
          <h4
            style={{
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            Video de interes
          </h4>
          <YouTube videoId="P-SC45A0itc" opts={opts} onReady={this._onReady} />
        </div>
      </div>
    );
  }

  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }
}
export default Multimedia;
