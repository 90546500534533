import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "reactstrap";
import {
  PhoneVibrateFill,
  TelephoneXFill,
  EnvelopeFill,
  Facebook,
  Twitter,
  Whatsapp,
  HouseDoorFill,
} from "react-bootstrap-icons";
import { Card, CardGroup, Button } from "react-bootstrap";

export default function Contenedor() {
  return (
    <div>
      <Container fluid="md">
        <Row>
          <Col>
            <CardGroup
              style={{
                width: "100%",
                background: "#E5ECEC",
                color: "#305E5E",
                height: "11vh",
              }}
            >
              <Card>
                <Card.Footer
                  style={{
                    height: "11vh",
                  }}
                >
                  <HouseDoorFill color="royalblue" size={30} /> Ecuador-Quito
                </Card.Footer>
              </Card>
              <Card>
                <Card.Footer
                  style={{
                    height: "11vh",
                  }}
                >
                  <TelephoneXFill color="royalblue" size={30} /> 062-806-160
                </Card.Footer>
              </Card>
              <Card
                style={{
                  width: "60%",
                }}
              >
                <Card.Footer
                  style={{
                    height: "11vh",
                  }}
                >
                  <PhoneVibrateFill color="royalblue" size={30} />
                  098-732-6315
                </Card.Footer>
              </Card>
              <Card>
                <Card.Footer
                  style={{
                    height: "11vh",
                  }}
                >
                  <EnvelopeFill color="royalblue" size={30} />{" "}
                  ventas@siccpetr.com
                </Card.Footer>
              </Card>
            </CardGroup>
          </Col>
          <Col xs lg="3">
            <Card>
              <Card.Footer
                style={{
                  height: "11vh",
                }}
              >
                <Button
                  href="http://www.facebook.com"
                  style={{
                    alignContent: "center",
                    background: "#FFFFFF",
                  }}
                >
                  <Facebook color="royalblue" size={30} />
                </Button>{" "}
                <Button
                  href="https://twitter.com"
                  style={{
                    alignContent: "center",
                    background: "#FFFFFF",
                  }}
                >
                  <Twitter color="royalblue" size={30} />
                </Button>{" "}
                <Button
                  href="https://wa.me/+5930987326315"
                  style={{
                    alignContent: "center",
                    background: "#FFFFFF",
                  }}
                >
                  <Whatsapp color="green" size={30} />
                </Button>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
      <br />
    </div>
  );
}
