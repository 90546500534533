import React from "react";
import { ChatLeftQuote } from "react-bootstrap-icons";
import Multimedia from "../components/Container/Multimedia";
import { ListGroup, Badge } from "react-bootstrap";
import { ArrowRightSquareFill } from "react-bootstrap-icons";

const Politics = () => {
  return (
    <div
      style={{
        disptextlay: "flex",
        justifyContent: "justify",
        alignItems: "justify",
        padding: "5em",
        textAlign: "justify",
      }}
      className="row"
    >
      <div className="col-sm-9">
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <h1>Política de Calidad</h1>
        </div>
        <br />
        <div>
          <p>
            <ChatLeftQuote color="royalblack" size={30} />{" "}
            <Badge variant="info">SICCPETR S.A.</Badge> Empresa de servicios de
            inspección y control de calidad para el sector petrolero establece
            como política de calidad:
          </p>
          <p>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <ArrowRightSquareFill color="#4d88ff" size={20} /> Implementar,
                desarrollar y mantener un sistema de gestión de la calidad
                basado en las normas ISO 9001 e ISO 17020 versión vigente.
              </ListGroup.Item>
              <ListGroup.Item>
                <ArrowRightSquareFill color="#4d88ff" size={20} /> Lograr la
                satisfacción de nuestros clientes, como una forma de asegurar la
                continuidad y crecimiento sostenido de los servicios.
              </ListGroup.Item>
              <ListGroup.Item>
                <ArrowRightSquareFill color="#4d88ff" size={20} /> Cumplir
                estrictamente la legislación vigente.
              </ListGroup.Item>
              <ListGroup.Item>
                <ArrowRightSquareFill color="#4d88ff" size={20} /> Mejorar
                continuamente nuestro sistema de calidad con el involucramiento
                de todo el personal de{" "}
                <Badge variant="info">SICCPETR S.A.</Badge> promoviendo su
                desarrollo para lograr un mejor desempeño dentro de sus
                actividades.
              </ListGroup.Item>
              <ListGroup.Item></ListGroup.Item>
            </ListGroup>
          </p>
        </div>
        <br />
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <h1>Política de independencia, imparcialidad y confidencialidad</h1>
        </div>
        <br />
        <div>
          <p>
            <ChatLeftQuote color="royalblack" size={30} /> Las actividades de
            inspección y control de calidad realizadas por{" "}
            <Badge variant="info">SICCPETR S.A.</Badge> se rigen por principios
            de independencia, imparcialidad y confidencialidad.
          </p>
          <p>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <ArrowRightSquareFill color="#4d88ff" size={20} /> El
                aseguramiento de estos principios permite emitir criterios
                basados en evidencias objetivas de conformidad y no estarán
                influidas por otros intereses o partes, cumpliendo normativa y
                disposiciones de entidades de Acreditación y Organismos
                Reguladores.
              </ListGroup.Item>
              <ListGroup.Item>
                <ArrowRightSquareFill color="#4d88ff" size={20} /> El personal
                de <Badge variant="info">SICCPETR S.A.</Badge> es responsable de
                llevar a cabo la inspección y no será el diseñador, productor,
                proveedor, instalador, comprador, propietario, usuario de los
                elementos que inspeccionan, ni el representante autorizado de
                ninguna de estas partes ni estará vinculado con una entidad
                legal separada involucrada en los ítems a inspeccionar.
              </ListGroup.Item>
              <ListGroup.Item>
                <ArrowRightSquareFill color="#4d88ff" size={20} /> El personal
                de <Badge variant="info">SICCPETR S.A.</Badge> declara su
                imparcialidad a la hora de ejecutar las tareas que les son
                asignadas. El personal de{" "}
                <Badge variant="info">SICCPETR S.A.</Badge>, revelara cualquier
                situación de la que tengan conocimiento que pudiera presentar un
                conflicto de intereses.
              </ListGroup.Item>
              <ListGroup.Item>
                <ArrowRightSquareFill color="#4d88ff" size={20} /> El personal
                que integra <Badge variant="info">SICCPETR S.A.</Badge> será
                custodio de la información entregada por el cliente, si se
                llegara a detectar una divulgación no autorizada este será
                responsable de los daños y perjuicios que dicho incumplimiento
                llegase a ocasionar, <Badge variant="info">SICCPETR S.A.</Badge>{" "}
                podrá tomar acciones de tipo civil y penal a fin de salvaguardar
                los intereses propios y de clientes, considerando esta una falta
                grave podrá ser causa de despido como lo estipula el contrato de
                trabajo en su cláusula cuarta, registrada en el Ministerio de
                Relaciones Laborales.
              </ListGroup.Item>
              <ListGroup.Item></ListGroup.Item>
            </ListGroup>
          </p>
        </div>
      </div>
      <div className="col-md-3">
        <Multimedia></Multimedia>
      </div>
    </div>
  );
};

export default Politics;
