import React from "react";
import { ChatLeftQuote } from "react-bootstrap-icons";
import Multimedia from "../components/Container/Multimedia";
import { ListGroup, Card } from "react-bootstrap";
import { Row, Col, Container } from "reactstrap";
import { ArrowRightSquareFill } from "react-bootstrap-icons";

const Services = () => {
  return (
    <div
      style={{
        disptextlay: "flex",
        justifyContent: "justify",
        alignItems: "justify",
        padding: "5em",
        textAlign: "justify",
      }}
      className="row"
    >
      <div className="col-sm-9">
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <h1>Inspección Visual</h1>
        </div>
        <br />
        <div>
          <p>
            <ChatLeftQuote color="royalblack" size={30} /> La inspección visual
            es la técnica mas antigua entre los Ensayos No Destructivos, y
            también la mas usada por su versatilidad y su bajo costo.
          </p>
          <p>
            En ella se emplea como instrumento principal, el ojo humano, el cual
            es complementado frecuentemente con instrumentos de magnificación,
            iluminación y medición.
          </p>
          <p>
            Esta técnica es y ha sido siempre un complemento para todos los
            demás ensayos No destructivos, ya que a menudo la evaluación final
            se hace por medio de una inspección visual.
          </p>
          <div>
            <Container fluid="md">
              <Row>
                <Col xs lg="3"></Col>
                <Col xs lg="9">
                  <Card
                    style={{
                      width: "60%",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Card.Img variant="top" src={require("../images/f5.jpg")} />
                    <Card.Footer>
                      <small className="text-muted">Inspección exaustiva</small>
                    </Card.Footer>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <br />
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <h1>Inspección por Partículas Magnéticas</h1>
        </div>
        <br />
        <div>
          <p>
            <ChatLeftQuote color="royalblack" size={30} /> El método de ensayo
            no destructivo por partículas magnéticas es usado para la detección
            de posibles discontinuidades en la inspección de materiales
            ferromagnéticos. La técnica de partículas magnéticas es una técnica
            no destructiva relativamente sencilla, basada en la propiedad de
            ciertos materiales de convertirse en un imán.
          </p>
          <p>
            Es un método que utiliza principalmente corriente eléctrica para
            crear un flujo magnético en una pieza y al aplicarse un polvo
            ferromagnético produce la indicación donde exista distorsión en las
            líneas de flujo (fuga decampo), Dicho procedimiento se utiliza para
            la detección de discontinuidades superficiales y subsuperficiales
            (hasta 1/4" de profundidad aproximadamente, para situaciones
            prácticas) en materiales ferromagnéticos. Propiedad física en la que
            se basa. (Permeabilidad)
          </p>
          <p>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <ArrowRightSquareFill color="#4d88ff" size={20} /> Propiedad de
                algunos materiales de poder ser magnetizados.
              </ListGroup.Item>
              <ListGroup.Item>
                <ArrowRightSquareFill color="#4d88ff" size={20} /> La
                característica que tienen las líneas de flujo de alterar su
                trayectoria cuando son interceptadas por un cambio de
                permeabilidad.
              </ListGroup.Item>
              <ListGroup.Item></ListGroup.Item>
            </ListGroup>
          </p>
          <div>
            <Container fluid="md">
              <Row>
                <Col xs lg="3"></Col>
                <Col xs lg="9">
                  <Card
                    style={{
                      width: "60%",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Card.Img variant="top" src={require("../images/f6.jpg")} />
                    <Card.Footer>
                      <small className="text-muted">
                        Inspección por partículas
                      </small>
                    </Card.Footer>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <br />
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <h1>Inspección de medición de espesor por ultrasonido</h1>
        </div>
        <br />
        <div>
          <p>
            <ChatLeftQuote color="royalblack" size={30} /> La medición
            ultrasónica de espesores es una técnica de ensayo no destructivo
            para la cual no es necesario cortar ni seccionar el material. Se
            trata de una método rápido, fiable y versátil que, a diferencia del
            uso de un micrómetro o un calibre, requiere el acceso a solamente
            una pared de la pieza a medir. Es por ello que se usa ampliamente
            para determinar el espesor de materiales como, por ejemplo, caños,
            tubos, válvulas, tanques, calderas y otros recipientes a presión,
            cascos navales o cualquier material sujeto a la corrosión y el
            desgaste.
          </p>
          <p>
            Los primeros medidores ultrasónicos comerciales usaban principios
            derivados del sonar y se introdujeron en el mercado hace unos 65
            años. Los instrumentos pequeños y portátiles, optimizados para una
            amplia variedad de aplicaciones se hicieron comunes en los años ‘70.
            Sin embargo, avances posteriores en la tecnología de
            microprocesadores condujeron a nuevos niveles de performance en los
            sofisticados instrumentos en miniatura y fáciles de usar que tenemos
            actualmente a nuestro alcance.
          </p>
          <div>
            <Container fluid="md">
              <Row>
                <Col xs lg="3"></Col>
                <Col xs lg="9">
                  <Card
                    style={{
                      width: "60%",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Card.Img variant="top" src={require("../images/f7.jpg")} />
                    <Card.Footer>
                      <small className="text-muted">
                        Inspección de medición
                      </small>
                    </Card.Footer>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <Multimedia></Multimedia>
      </div>
    </div>
  );
};

export default Services;
