import React from "react";
import { RViewerTrigger, RViewer } from "react-viewerjs";

export default function Galeria() {
  let imagenes = [
    require("../../gallery/2.jpg"),
    require("../../gallery/5.jpg"),
    require("../../gallery/7.jpg"),
    require("../../gallery/9.jpg"),
    require("../../gallery/15.jpg"),
    require("../../gallery/16.jpg"),
    require("../../gallery/20.jpg"),
    require("../../gallery/24.jpg"),
    require("../../gallery/50.jpg"),
    require("../../gallery/40.jpg"),
    require("../../gallery/34.jpg"),
    require("../../gallery/36.jpg"),
    require("../../gallery/10.jpg"),
    require("../../gallery/11.jpg"),
    require("../../gallery/37.jpg"),
    require("../../gallery/48.jpg"),
    require("../../gallery/30.jpg"),
    require("../../gallery/1.jpg"),
    require("../../gallery/37.jpg"),
    require("../../gallery/47.jpg"),
  ];

  return (
    <div>
      <RViewer imageUrls={imagenes}>
        <div
          style={{
            marginTop: "2px",
          }}
        >
          {imagenes.map((imagen, index) => {
            return (
              <RViewerTrigger index={index}>
                <img
                  src={imagen}
                  style={{
                    width: "52px",
                    height: "50px",
                    marginLeft: "2px",
                    border: "2px solid black",
                  }}
                ></img>
              </RViewerTrigger>
            );
          })}
        </div>
      </RViewer>
    </div>
  );
}
