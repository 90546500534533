import React from "react";
import Multimedia from "../components/Container/Multimedia";
import { Form, Row, Col, Card, Button } from "react-bootstrap";

const Contact = () => {
  return (
    <div
      style={{
        disptextlay: "flex",
        justifyContent: "justify",
        alignItems: "justify",
        padding: "5em",
        textAlign: "justify",
      }}
      className="row"
    >
      <div className="col-sm-9">
        <div>
          <Card className="col-sm-8">
            <Card.Header>Sugerencias o comentarios</Card.Header>
            <Card.Body>
              <Form>
                <Row>
                  <Col>
                    <Form.Label>Nombres:</Form.Label>
                    <Form.Control placeholder="Ingrese nombre y apellido" />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Label>Email:</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Ingrese un email valido"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Label>Asunto:</Form.Label>
                    <Form.Control placeholder="Ingrese el asunto" />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Label>Mensaje:</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                  </Col>
                </Row>
                <Row>
                  <Col style={{ paddingTop: "10px" }}>
                    <Button variant="primary">Enviar</Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className="col-md-3">
        <Multimedia></Multimedia>
      </div>
    </div>
  );
};

export default Contact;
