import React from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Badge, ListGroup, Card, CardDeck } from "react-bootstrap";
import Multimedia from "../components/Container/Multimedia";

const Home = () => {
  return (
    <div
      style={{
        disptextlay: "flex",
        justifyContent: "justify",
        alignItems: "justify",
        padding: "5em",
        textAlign: "justify",
      }}
      className="row"
    >
      <div className="col-sm-9">
        <div>
          <Badge variant="warning">Somos </Badge> una empresa enfocada en la
          prestación de servicios de inspección, con la aplicación de ensayos no
          destructivos y enfocados en el sector petrolero, entre los cuales
          contamos con:
        </div>
        <br />
        <ListGroup variant="flush" style={{ width: "20rem" }}>
          <ListGroup.Item>Magnetic Particle Testing</ListGroup.Item>
          <ListGroup.Item>Ultrasonic Testing</ListGroup.Item>
          <ListGroup.Item>Visual and Optical Testing</ListGroup.Item>
        </ListGroup>
        <br />
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Se denomina ensayo no destructivo (también llamado END, o en inglés
          NDT de nondestructive testing) a cualquier tipo de prueba practicada a
          un material que no altere de forma permanente sus propiedades físicas,
          químicas, mecánicas o dimensionales. Los ensayos no destructivos
          implican un daño imperceptible o nulo. Los diferentes métodos de
          ensayos no destructivos se basan en la aplicación de fenómenos físicos
          tales como ondas electromagnéticas, acústicas, elásticas, emisión de
          partículas subatómicas, capilaridad, absorción y cualquier tipo de
          prueba que no implique un daño considerable a la muestra examinada.
        </div>
        <br />
        <CardDeck>
          <Card>
            <Card.Img variant="top" src={require("../gallery/45.jpg")} />
            <Card.Footer>
              <small className="text-muted">Responsabilida</small>
            </Card.Footer>
          </Card>
          <Card>
            <Card.Img variant="top" src={require("../gallery/4.jpg")} />
            <Card.Footer>
              <small className="text-muted">Calidad</small>
            </Card.Footer>
          </Card>
          <Card>
            <Card.Img variant="top" src={require("../gallery/56.jpg")} />
            <Card.Footer>
              <small className="text-muted">Trabajo</small>
            </Card.Footer>
          </Card>
          <Card>
            <Card.Img variant="top" src={require("../images/f4.jpg")} />
            <Card.Footer>
              <small className="text-muted">Experiencia</small>
            </Card.Footer>
          </Card>
        </CardDeck>
      </div>
      <div className="col-md-3">
        <Multimedia></Multimedia>
      </div>
    </div>
  );
};

export default Home;
