import React from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages";
import Politics from "./pages/politics";
import Services from "./pages/services";
import Contact from "./pages/contact";
import Appeals from "./pages/appeals";

import "bootstrap/dist/css/bootstrap.css";
import Contenedor from "./components/Container/Contenedor";

function App() {
  return (
    <diV>
      <Router>
        <Navbar className="menu" />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/politics" component={Politics} />
          <Route path="/services" component={Services} />
          <Route path="/contact-us" component={Contact} />
          <Route path="/appeals" component={Appeals} />
        </Switch>
      </Router>
      <Contenedor></Contenedor>
    </diV>
  );
}

export default App;
